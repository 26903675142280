:root {
    --agenda-radius: 0;
}

.agendaHeader {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--space-sm);
}
@media(min-width:800px){
    .agendaHeader {
        display: flex;
        justify-content: space-between;
    }
}

.agenda, .day {
    display: grid;
    grid-template-columns: 1fr;
    gap: .5rem;
}

.dayDivider {
    background-color: var(--primary);
    border-radius: var(--agenda-radius);
    padding: .5rem 1rem;
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
}

.timeSlot {
    display: grid;
    grid-template-columns: 1fr;
    /* gap: .5rem; */
    /* padding: .5rem;
    border: 1px solid rgba(0, 255, 255, 0.2);
    border-radius: var(--agenda-radius); */
}
.timingsAndTags {
    background-color: var(--light);
    border: 1px solid var(--light);
    border-radius: calc(var(--agenda-radius) * 1);
    padding: .5rem 1rem;
}
@media(min-width:600px){
    .timeSlot {
        grid-template-columns: 9rem auto;
    }
    .timingsAndTags {
        padding: 1rem;
    }
}

.sessions {
    display: flex;

    /* comment-out flex-direction to display sessions in columns */
    /* flex-direction: column; */

    gap: .5rem;
}

.session {
    flex: 1;
    padding: 1rem;
    /* border-top: 1px solid var(--light); */
    border: 1px solid var(--light);
    /* background-color: rgb(243, 245, 253); */
    border-radius: calc(var(--agenda-radius) * 1);
}

.sessionTitle {
    /* max-width: 50rem; */
}

.sessionTagGrid {
    display: flex;
    align-items: center;
    gap: .25rem
}

/* speakerGrid */

.sessionSpeakerGrid {
    display: flex;
    gap: var(--space-sm);
    flex-wrap: wrap;
}

.sessionSpeakerAvatar {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    overflow: hidden;
    margin-bottom: .5rem;
}
.sessionSpeakerAvatar img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

@media(min-width:1200px) {
    .sessionSpeakerGrid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.sessionTag {
    display: inline-block;
    padding: 2px 8px;
    /* border: 1px solid aqua; */
    border-radius: calc(var(--agenda-radius) * 2);
    background-color: rgba(0, 0, 0, 0.298);
    background-color: var(--primary);
    font-size: .8rem;
}

.test {
    border: 1px solid rgb(255, 0, 208);
}

.speakerTag {
    display: inline-block;
    margin-bottom: .5rem;
    padding: 2px 8px;
    border-radius: calc(var(--agenda-radius) * 2);
    background-color: var(--accent);
    color: black;
    font-size: .8rem;
    font-weight: 500;
    text-transform: uppercase;
}




/* updated layout (columns) */

.sessionsWrapper {
    flex:1;
    display: flex;
    flex-direction: column;
    /* gap: .5rem; */
}
.sessionStream {
    padding: .5rem 1rem;
    border-radius: calc(var(--agenda-radius) * 1);
    background-color: var(--secondary);
}

.session {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}



@media(max-width:600px) {
    /* updated layout (rows) */
    
    .sessions {
        flex-direction: column;
    }
    .sessionsWrapper {
        flex:1;
        display: flex;
        /* flex-direction: row; */
        /* gap: .5rem; */
    }

}